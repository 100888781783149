import { Loading } from '@geist-ui/core'
import { navigate } from 'gatsby'
import React, { useEffect } from 'react'

import { Firebase } from '../config/firebase'

export default function Home() {
  useEffect(() => {
    async function asyncHandler(user) {
      if (user) {
        navigate('/projects')
      } else {
        navigate('/login')
      }
    }
    Firebase.auth().onAuthStateChanged(asyncHandler)
  }, [])

  return <Loading style={{ marginTop: 40 }}>Loading Application</Loading>
}
